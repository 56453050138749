import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { exploreData } from '../../../utils/commonStatic';
import { getData, getCard } from '../../../api/getDataStatic';
import CardPagesInnovation from '../../CardPages/CardPagesInnovation';
import {
  Box,
  CircularProgress,
  Grid,
  Hidden,
  useTheme,
  withStyles,
} from '@material-ui/core';
import MuiTypography from '@material-ui/core/Typography';

const Typography = withStyles((theme) => ({
  body1: {
    fontFamily: 'Lato',
  },
}))(MuiTypography);

function Innovation() {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState(null);

  const internalTitle = 'Innovation';

  const [locale, setLocale] = useState(i18n.language);
  const [fetchingData, setFetchingData] = useState(true);
  const [cardZeus, setCardZeus] = useState(null);
  const [cardEtef, setCardEtef] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    i18n.on('languageChanged', (t) => {
      setFetchingData(true);
      setLocale(t);
    });
    setData(null);

    try {
      const fetchData = async () => {
        const res = await getData({
          locale: locale,
          internalTitle: internalTitle,
        });

        const resCardZeus = await getCard({
          locale: locale,
          internalTitle: internalTitle,
          title: 'zeus',
        });
        setCardZeus(resCardZeus);
        const resCardEtef = await getCard({
          locale: locale,
          internalTitle: internalTitle,
          title: 'etef',
        });
        setCardEtef(resCardEtef);

        setData(exploreData(res));
      };
      if (fetchingData) {
        fetchData();
      }
      return () => setFetchingData(false);
    } catch (e) {
      console.log(e);
    }
  }, [fetchingData, locale, i18n]);

  return (
    <>
      <Helmet>
        <title>
          {t('common:app.name')} | {t('pages:innovation.title')}
        </title>
      </Helmet>

      <CardPagesInnovation
        slogan={data?.innovation?.slogan}
        image={data?.innovation?.sloganImage}
      />

      <Grid container justify="center" direction="row" wrap="wrap" spacing={3}>
        <Grid item xs={12}>
          <Box
            style={{
              backgroundColor: 'white',
              margin: '0px 0',
              paddingBottom: '50px',
            }}
            justifyContent="center"
            alignItems="center"
          >
            {data ? (
              <>
                <h5
                  style={{
                    textAlign: 'center',
                    color: theme.palette.primary.main,
                    fontWeight: 'bold',
                    fontSize: '1.5rem',
                    textTransform: 'uppercase',
                    width: '100%',
                    padding: '30px 0',
                    margin: '0',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: data?.zeus?.textTitle,
                  }}
                ></h5>
                <Hidden mdDown>
                  <img
                    style={{
                      width: '518px',
                      display: 'block',
                      float: 'right',
                      margin: '20px 20px 0 20px',
                    }}
                    alt={data?.zeus?.textTitle}
                    src={data?.zeus?.textImage}
                  ></img>
                </Hidden>
                <Typography
                  style={{
                    width: '100%',
                    backgroundColor: 'white',
                    marginBottom: '0px',
                    padding: '5px 50px 5px 50px',
                    textAlign: 'justify',
                    lineHeight: '1.05',
                    color: 'rgb(128,128,128)',
                  }}
                  gutterBottom
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: data?.zeus?.text,
                    }}
                  ></span>
                </Typography>
              </>
            ) : (
              <Box
                style={{ height: '300px' }}
                display="flex"
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress size={200} />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>

      <CardPagesInnovation
        sloganExist={false}
        subTextTitle={data?.zeus?.subTextTitle}
        subText={data?.zeus?.subText}
        card={cardZeus}
        centalImage={data?.zeus?.centralImage}
        textNoBrackets={data?.zeus?.textNoBrackets}
        subTextNoBrackets={data?.zeus?.subTextNoBrackets}
      />

      <CardPagesInnovation
        sloganExist={false}
        title={data?.etef?.textTitle}
        text={data?.etef?.text}
        subTextTitle={data?.etef?.subTextTitle}
        subText={data?.etef?.subText}
        card={cardEtef}
        textImage={data?.etef?.textImage}
        centalImage={data?.etef?.centralImage}
        margintop={'30px'}
        textNoBrackets={data?.etef?.textNoBrackets}
        subTextNoBrackets={data?.etef?.subTextNoBrackets}
      />
    </>
  );
}

export default Innovation;
