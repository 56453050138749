export const menuMobileData = ({ t, i18n }) => {
  const p4f_batteries =
    i18n.language === 'it'
      ? 'https://www.fincantieri.com/it/gruppo/controllate-collegate/power4future/'
      : 'https://www.fincantieri.com/en/gruppo/controllate-collegate/power4future/';

  return [
    {
      exact: false,
      to: '/home',
      labelLink: t('menu.home'),

      subMenu: [
        { title: t('home.whoweare'), linkTo: '/home/whoweare' },
        { title: t('home.mission'), linkTo: '/home/mission' },
        { title: t('home.operating'), linkTo: '/home/operating' },
        { title: t('home.governance'), linkTo: '/home/governance' },
      ],
    },
    {
      exact: false,
      to: '/target',
      labelLink: t('menu.target'),

      subMenu: [
        {
          keyName: 'shipboard',
          title: t('target.shipboard'),
          linkTo: '/target/shipboard',
          subMenuListItems: [
            {
              linkTo: '/target/shipboard/propulsion',
              title: t('shipboard.propulsion'),
            },
            {
              linkTo: '/target/shipboard/energy',
              title: t('shipboard.energy'),
            },
            {
              linkTo: '/target/shipboard/electro',
              title: t('shipboard.electro'),
            },
            {
              linkTo: '/target/shipboard/shiptoshore',
              title: t('shipboard.shiptoshore'),
            },
          ],
        },
        {
          keyName: 'plants',
          title: t('target.plants'),
          linkTo: '/target/equipment',
          subMenuListItems: [
            {
              linkTo: '/target/equipment/cold',
              title: t('shipboard.cold'),
            },
            {
              title: t('target.land'),
              linkTo: '/target/equipment/land',
            },
          ],
        },

        {
          keyName: 'industrial',
          title: t('target.industrial'),
          linkTo: '/target/industrial',
        },
        {
          keyName: 'lifecycle',
          title: t('target.lifecycle'),
          linkTo: '/target/lifecycle',
        },
      ],
    },
    {
      exact: false,
      to: '/photovoltaic',
      labelLink: t('menu.photovoltaic'),
    },
    {
      exact: false,
      to: '/innovation',
      labelLink: t('menu.innovation'),
      subMenu: [
        { title: t('innovation.seademo'), linkTo: '/innovation/seademo' },
        { title: t('innovation.shift2dc'), linkTo: '/innovation/shift2dc' },
      ],
    },
    {
      exact: false,
      to: '/iter',
      labelLink: t('menu.iter'),
    },
    {
      exact: false,
      target: '_blank',
      to: { pathname: p4f_batteries },
      labelLink: t('menu.p4f_batteries'),
    },
    {
      exact: false,
      to: '/certificates',

      labelLink: t('menu.certificates'),
    },
    {
      exact: false,

      to: '/media',
      labelLink: t('menu.media'),
    },
    {
      exact: false,
      keyName: 'careers',
      to: '/contacts',
      labelLink: t('menu.contacts'),
      redirect: {
        link: 'https://www.fincantieri.com/it/lavora-con-noi/',
        keyName: 'careers',
        title: t('menu.careers'),
      },
      subMenu: [
        {
          linkTo: '',
          title: t('menu.careers'),
        },
      ],
    },
  ];
};
