import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import SquareBrackets from '../../BoxSquared/SquareBrackets';
import imageOperatingITA from '../../../assets/img/mod_OP_ITA.jpg';
import imageOperatingENG from '../../../assets/img/mod_OP_ENG.jpg';
import lighting from '../../../assets/img/lighting.png';

import { getData } from '../../../api/getDataStatic';
import { exploreData } from '../../../utils/commonStatic';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: 'white',
    height: '100%',
    padding: 30,
  },
  upper: {
    textTransform: 'uppercase',
  },
  centerText: {
    textAlign: 'center',
  },
  orange: {
    color: theme.palette.primary.main,
  },
  image: {
    backgroundColor: 'white',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    '& img': {
      objectFit: 'contain',
      height: '100%',
      width: '100%',
      opacity: 0.8,
    },
  },
  squarebracketsDiv: {
    padding: 20,
    width: '65%',
    '@media screen and (max-width: 600px)': {
      width: '80%',
    },
  },
  bullet: {
    color: theme.palette.secondary.main,
    listStyle: 'none',
    marginLeft: -40,
    '& > li': {
      fontSize: '0.93rem',
      '&:before': {
        color: theme.palette.primary.main,
        content: '"\\2022"',
        fontWeight: 'bold',
        display: 'inline-block',
        width: '1em',
      },
    },
  },
  lato: {
    fontFamily: 'Lato',
    textAlign: 'justify',
    '& ul': {
      listStyle: 'none',
      fontSize: '1rem',
      marginLeft: -20,
      '&  li': {
        fontSize: '0.93rem',
        '&:before': {
          color: theme.palette.primary.main,
          content: '"\\2022"',
          fontWeight: 'bold',
          display: 'inline-block',
          width: '1em',
        },
      },
    },
  },
}));

function OperatingProcedures() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const [data, setData] = useState(null);

  const internalTitle = 'Home';
  const [locale, setLocale] = useState(i18n.language);
  const [fetchingData, setFetchingData] = useState(true);

  useEffect(() => {
    i18n.on('languageChanged', (t) => {
      setFetchingData(true);
      setLocale(t);
    });
    setData(null);

    try {
      const fetchData = async () => {
        const res = await getData({
          locale: locale,
          internalTitle: internalTitle,
        });

        setData(exploreData(res));
      };

      if (fetchingData) {
        fetchData();
      }

      return () => setFetchingData(false);
    } catch (e) {
      console.log(e);
    }
  }, [fetchingData, locale, i18n]);

  return (
    <>
      <Helmet>
        <title>
          {t('common:app.name')} | {t('pages:operatingProcedures.title')}
        </title>
      </Helmet>
      <Grid container justify="center" direction="row" wrap="wrap" spacing={0}>
        <Grid item xs={12} md={6}>
          <Box className={classes.image}>
            <img
              src={locale === 'it' ? imageOperatingITA : imageOperatingENG}
              alt="operating procedures"
            ></img>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            className={`${classes.box}`}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            {data?.model?.text ? (
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
                alignContent="center"
              >
                <SquareBrackets
                  className={`${classes.upper} ${classes.centerText}  ${classes.squarebracketsDiv}`}
                  paddingchoise={'20px 20px'}
                  colorchoise={theme.palette.secondary.main}
                >
                  <img src={lighting} alt="lighting bolt" />
                  <Typography
                    color="secondary"
                    variant="h3"
                    dangerouslySetInnerHTML={{
                      __html: data?.model?.sloganTitle,
                    }}
                  ></Typography>
                </SquareBrackets>
                <Typography
                  variant="h5"
                  color="primary"
                  className={classes.lato}
                  dangerouslySetInnerHTML={{
                    __html: data?.model?.textTitle,
                  }}
                />
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  color="secondary"
                  className={classes.lato}
                  dangerouslySetInnerHTML={{
                    __html: data?.model?.text,
                  }}
                />
              </Box>
            ) : (
              <Box
                style={{ height: '300px' }}
                display="flex"
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress size={200} />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default OperatingProcedures;
