import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import MuiTypography from '@material-ui/core/Typography';
import SHIFT2DC_LOGO from '../../../assets/staticsCalls/whoweare/shift2DC.png';
import SHIFT2DC_INFOGRAFICA from '../../../assets/staticsCalls/innovation/shift2dc_infografica.png';

const Typography = withStyles((theme) => ({
  body1: {
    fontFamily: 'Lato',
  },
}))(MuiTypography);

function Shif2DC() {
  const { t, i18n } = useTranslation();
  const [locale, setLocale] = useState(i18n.language);

  useEffect(() => {
    i18n.on('languageChanged', (t) => {
      setLocale(t);
    });
  }, [locale, i18n]);

  return (
    <>
      <Helmet>
        <title>
          {t('common:app.name')} | {t('pages:innovation.shift2dc')}
        </title>
      </Helmet>
      <Grid container justify="center" direction="row" wrap="wrap" spacing={3}>
        <Grid item xs={12}>
          <Box
            style={{
              backgroundColor: 'white',
              margin: '20px 0',
              paddingBottom: '50px',
            }}
            display="flex"
            flexWrap="wrap"
            justifyContent="center"
            alignItems="center"
          >
            <img
              style={{ margin: '20px 0' }}
              alt="Shift2DC Logo"
              src={SHIFT2DC_LOGO}
            ></img>
            <Typography
              style={{
                width: '100%',
                backgroundColor: 'white',
                marginBottom: '20px',
                padding: '0 44px 44px 44px',
                textAlign: 'justify',
                lineHeight: '1.05',
                color: 'rgb(128,128,128)',
              }}
              gutterBottom
            >
              <p
                dangerouslySetInnerHTML={{
                  __html: t('pages:innovation.shift2dc_page.text'),
                }}
              ></p>
            </Typography>
            <img
              style={{ margin: '0px', width: '95%' }}
              alt="Shift2DC"
              src={SHIFT2DC_INFOGRAFICA}
            ></img>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Shif2DC;
